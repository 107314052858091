/**
 * SME  
 */

* {
    font-family: Roboto, sans-serif;
}

html,
body {
    position: fixed;
    height: 100%;
    width: 100%;
    margin: 0;
}

h2 {
    text-shadow: 1px 1px 2px black, 0 0 25px #0f0, 0 0 5px black;
    font-size: 1.5rem;
    color: white;
}

p {
    line-height: 2.1;
    letter-spacing: 2px;
    /*margin-bottom: 1.5em;*/
    color: black;
    font-size: 90%;
    text-align: justify; 
    text-justify: inter-word;
}

    .photo {
        max-width: 75%;
    }

    

.highlight {
    font-size: 100%;
    color: white;
    text-shadow: 1px 1px 2px black, 0 0 25px #0f0, 0 0 5px black;
}

.box {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
}


.box .row {
    /*  border: 1px dotted grey;*/
}


.box .row.content {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;

    overflow: scroll;
    background: white;
    z-index: 99999;
}

.box .row.header {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;

    width: 100%;
    height: 20%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box .row.footer {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: fit-content;

    background: black;
    

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 99999999;
}




.three {
    height: 100%;
 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}








.footer-social-icons {
    width: fit-content;
    padding-top: 3px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.social-icon {
    color: #fff;
    margin-left: 2.5px;
    margin-right: 2.5px;
    background-color: #322f30;

    padding: 0px;
    border-radius: 3px;
}

#icon {
    /*  padding: 10px 14px;
  border-radius: 4px;*/

    padding-top: 6px;
    padding-bottom: 6px;

    padding-right: 6px;

    padding-left: 6px;

    border-radius: 3px;
}

.social-icons a {
    color: #fff;
    text-decoration: none;
}

.fa-instagram:hover {
    color: #fff;
    vertical-align: middle;
    background-color: #d6249f;
    background: radial-gradient(circle at 30% 107%,
        #fdf497 0%,
        #fdf497 5%,
        #fd5949 45%,
        #d6249f 60%,
        #285aeb 90%);
}

.fa-twitter:hover {
    background-color: #00aced;
}

.fa-youtube:hover {
    background-color: #e64a41;
}

.fa-github:hover {
    background-color: #5a32a3;
}

.fa-apple:hover {
    background-color: #7d7d7d;
}

.fa-stack-overflow:hover {
    background-color: #ff9900;
}

.ai-google-scholar-square:hover {
    background-color: #4285f4;
}

.fa-soundcloud:hover {
    color: #fff;
    vertical-align: middle;
    background-color: #d6249f;
    background: linear-gradient(#ff7700, #ff3801);
}

.fa-envelope:hover {
    background-color: #f0f;
}

.fa-spotify:hover {
    background-color: #1db954;
}

.fa-reddit:hover {
    background-color: #ff5700;
}

.fa-medium:hover {
    background-color: #00ab6c;
}

.fa-meetup:hover {
    background-color: #ed1c40;
}

.fa-telegram:hover {
    background-color: #0088cc;
}

.more {
    font-weight: bold;
    color: #f0f;
    text-shadow: 0.5px 0.5px 0.5px black;
}

.card_image {
    border: 0;
    height: auto;
    display: block;

    width: 100%;
}

.card {
    background: white;
    margin-bottom: 2em;
    max-width: 100%;

}

.card a {
    color: black;
    text-decoration: none;
}

.card a:hover {
    box-shadow: 3px 3px 8px hsl(0, 0%, 80%);
}

.card-content {
    padding: 1.4em;
}

.card-content h2 {
    margin-top: 0;
    margin-bottom: 0.5em;
    font-weight: bold;
}

.card-content p {
    font-size: 90%;

       margin-bottom: 1.5em;
    color: black;
    /*font-size: 90%;*/
  text-align: justify; 
  text-justify: inter-word;
}

/* Flexbox stuff */

.cards {
    overflow: hidden;
    flex-wrap: wrap;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.card {
    flex: 1 0 500px;
    box-sizing: border-box;
    margin: 1rem 0.25em;
}




.centered {
    margin: 0 auto;
    padding: 0 1em;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



#landing_background {
    top: 0;
    opacity: 0.85;
    width: 100%;
    height: 20%;

    position: fixed;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background: black;
}

.sme {
    position: fixed;
    max-width: 100%;
    max-height: 20%;
}





@media screen and (min-width: 40em) {
    .card {
        max-width: calc(50% - 1em);
    }
}

@media screen and (min-width: 60em) {
    .card {
        max-width: calc(25% - 1em);
    }
}



@media only screen and (max-width: 768px) {
    .sme {
        width: 100%;
    }

    .cards {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;

        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .box .row.footer {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: fit-content;

        background: black;
        height: 200px;

      overflow-y: hidden;
        overflow-x: scroll;
        width: 100%;


        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .footer-social-icons {
        width: fit-content;
        /*   margin-right: 3px;
    margin-left: 3px;*/

        padding-right: 3.5px;
        padding-left: 3.5px;
        padding-top: 3px;
        padding-bottom: 7px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .three {


        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

}